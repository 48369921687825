@use "sass:math";
/* stylelint-disable keyframes-name-pattern */
@use '~@THC/styles/modifiers';

@use '~@THC/styles/color';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import './common.scss';


@media (min-width: ($signInModalBreakpoint + 1)) {
	@keyframes signInZoomIn {
		0% {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes signInZoomOut {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		100% {
			opacity: 0;
		}
	}
}

@media (max-width: $signInModalBreakpoint) {
	@keyframes signInZoomIn {
		0% {
			opacity: 0;
			transform: translateX(-50%) scale3d(0.3, 0.3, 0.3);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes signInZoomOut {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0;
			transform: translateX(-50%) scale3d(0.3, 0.3, 0.3);
		}

		100% {
			opacity: 0;
		}
	}
}

@mixin loadingPlaceholder() {
	align-items: center;
	background-image: linear-gradient(180deg, var(--thc-color-off-black), modifiers.darkShade(color.$off-black, 10%));
	background-size: 200% 200%;
	box-shadow: none;
	cursor: default;
	display: flex;
	justify-content: center;

	:global {
		animation: placeholder 1s ease-in-out infinite;
	}
}

@keyframes placeholder {
	0% {
		background-position: 0% 100%;
	}

	50% {
		background-position: 100% 0%;
	}

	100% {
		background-position: 0% 100%;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes slide-up {
	from {
		opacity: 0;
		transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slide-down {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}
