@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/typography' as typography;

.notFoundArea {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 60vh;

	.title {
		@include typography.heading2();

		margin-bottom: core.$space6;
	}

	.description {
		@include typography.large-base();

		margin-bottom: core.$space8;
	}

	.notFound {
		border-bottom: 0;
		max-width: core.$space * 40;
		padding: core.$space * 20 0;
		text-align: center;
		width: 100%;

		.button {
			margin-top: core.$space4;
		}
	}
}
