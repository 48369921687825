/* stylelint-disable number-max-precision */
/* stylelint-disable unit-disallowed-list */

/* @link https://utopia.fyi/clamp/calculator?a=375,1200,4—16|16—24|24—32|24—40|24—48|36—72|32—64|40—64|40—80&p=thc-fluid */

:root {
	--thc-fluid-4-16: clamp(0.25rem, -0.0909rem + 1.4545vi, 1rem);
	--thc-fluid-16-24: clamp(1rem, 0.7727rem + 0.9697vi, 1.5rem);
	--thc-fluid-24-32: clamp(1.5rem, 1.2727rem + 0.9697vi, 2rem);
	--thc-fluid-24-40: clamp(1.5rem, 1.0455rem + 1.9394vi, 2.5rem);
	--thc-fluid-24-48: clamp(1.5rem, 0.8182rem + 2.9091vi, 3rem);
	--thc-fluid-36-72: clamp(2.25rem, 1.2273rem + 4.3636vi, 4.5rem);
	--thc-fluid-32-64: clamp(2rem, 1.0909rem + 3.8788vi, 4rem);
	--thc-fluid-40-64: clamp(2.5rem, 1.8182rem + 2.9091vi, 4rem);
	--thc-fluid-40-80: clamp(2.5rem, 1.3636rem + 4.8485vi, 5rem);
}
