@use "sass:math";
/* stylelint-disable no-unknown-animations */

@use '~@THC/styles/theme/theme-vars.scss' as themeVars;

@use '~@THC/styles/core';

@use '~@THC/styles/typography';

@use '~@THC/styles/breakpoints';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '~@motorway/motorway-storybook-cra/dist/themeDark.css';

@import './common.scss';

@import './fluid.scss';

@import './products.scss';

// Fix till we destroy motorway-component-library and can load fonts from THC
@font-face {
	font-display: swap;
	font-family: 'NewTransport';
	font-weight: 500;
	src: url('~@THC/assets/fonts/NewTransport-Medium.woff2') format('woff2'),
		url('~@THC/assets/fonts/NewTransport-Medium.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Reckless';
	font-weight: 600;
	src: url('~@THC/assets/fonts/Reckless-SemiBold.woff2') format('woff2'),
		url('~@THC/assets/fonts/Reckless-SemiBold.woff') format('woff');
}

:root {
	--mw-dwa-header-height-desktop: #{core.$space * 12};
	--mw-dwa-header-height-mobile: #{core.$space9};
	--mw-dwa-scroll-padding-offset: 0px;
}

html:has(#similar-vehicles-section) {
	scroll-behavior: smooth;
}

html:has([data-navigation][data-is-sticky='true']) {
	--mw-dwa-scroll-padding-offset: var(--mw-dwa-header-height-mobile);

	@include breakpoints.minTablet {
		--mw-dwa-scroll-padding-offset: var(--mw-dwa-header-height-desktop);
	}
}

html:has([data-use-scroll-margin]) {
	scroll-behavior: smooth;
	scroll-padding-top: calc(core.$space4 + var(--mw-dwa-scroll-padding-offset));
}

body {
	-webkit-font-smoothing: antialiased;
}

// START extract to THC modifiers
.thc-typography-caption {
	@include typography.caption();
}

.thc-typography-heading1 {
	@include typography.heading1();
}

.thc-typography-heading2 {
	@include typography.heading2();
}

.thc-typography-heading3 {
	@include typography.heading3();
}

.thc-typography-heading4 {
	@include typography.heading4();
}

.thc-typography-heading5 {
	@include typography.heading5();
}

.thc-typography-heading6 {
	@include typography.heading6();
}

.thc-typography-heading7 {
	@include typography.heading7();
}

.thc-typography-hero {
	@include typography.hero();
}

.thc-typography-large {
	@include typography.large();
}

.thc-typography-link-large {
	@include typography.link-large();
}

.thc-typography-link-large-bold {
	@include typography.link-large-bold();
}

.thc-typography-link-regular {
	@include typography.link-regular();
}

.thc-typography-link-regular-bold {
	@include typography.link-regular-bold();
}

.thc-typography-link-small {
	@include typography.link-small();
}

.thc-typography-link-small-bold {
	@include typography.link-small-bold();
}

.thc-typography-regular {
	@include typography.regular();
}

.thc-typography-small {
	@include typography.small();
}
// END extract to THC modifiers

/* stylelint-disable-next-line no-duplicate-selectors */
:root {
	@include themeVars.getThemeVars('dark');
}

body {
	min-width: $motorwayProMinWidth;

	&.lightbox-modal-open,
	&.overflow-hidden {
		overflow: hidden; // Android is ok with this to prevent scrolling

		@supports (-webkit-overflow-scrolling: touch) {
			position: fixed; // iOS needs this to prevent scroll
		}
	}
}

.mw-infoBox-wrapper {
	z-index: 1000;
}

button {
	-webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

figure {
	margin: 0;
}

@include generateMarginClasses(1);

:root [class*="Modal-module_component__"],
:root [class*="Modal_component__"] {
	background: rgba(29, 29, 29, 0.75);
}

.mw-parent-theme-dark {
	:root & {
		background-color: var(--thc-color-primary-black);
	}

	:root & [class*="Button_primary"] {
		color: var(--button-color);
	}

	:root & .mw-checkbox input:checked:not(:disabled):hover,
	:root & .mw-checkbox input:checked:not(:disabled):active,
	:root & .mw-checkbox input:checked:not(:disabled) {
		background-color: var(--thc-color-primary-white);
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" stroke="%23242424" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2" d="m6 12.2 4.11 4.8L18 8"/></svg>');
		border-color: var(--thc-color-primary-white);
	}

	:root & .mw-radio input:checked:not(:disabled):hover,
	:root & .mw-radio input:checked:not(:disabled):active,
	:root & .mw-radio input:checked:not(:disabled) {
		background-color: var(--thc-color-primary-white);
		border-color: var(--thc-color-primary-white);
	}

	:root & .mw-input input:not([type=checkbox]),
	:root & .mw-input select,
	:root & .mw-select input:not([type=checkbox]),
	:root & .mw-select select {
		border-radius: var(--thc-border-radius-input);
	}

	:root & [class*="Checkbox-module_checkbox"] input:checked:not(:disabled) {
		background-color: var(--thc-color-primary-white);
		border-color: var(--thc-color-primary-white);
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path stroke="%23242424" stroke-width="2.2" d="M6 12.204 10.11 17 18 8" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	}

	:root & [class*="RadioItem-module_radioItem__"] label input:active + * {
		background-image: radial-gradient(ellipse at center,var(--thc-color-primary-white) 46.1333333333%,transparent 48.1333333333%);
	}

	:root & [class*="RadioItem-module_radioItem__"] label input:checked + * {
		border-color: var(--thc-color-primary-white);
		background-image: radial-gradient(ellipse at center,var(--thc-color-primary-white) 46.1333333333%,transparent 48.1333333333%);
	}

	#main {
		form {
			div.mw-input:not(.mw-input-custom) {
				margin-bottom: $marginGeneric * 3;

				@media (max-width: $breakpointTabletMobile) {
					margin-bottom: $marginGeneric * 5;
				}

				> span[tabindex='0'] {
					&:focus {
						outline: none;
					}
				}
			}

			.mw-input-intent-success {
				.mw-input {
					&[type='password'] {
						color: $green;
					}
				}
			}

			.mw-input-intent-error {
				.mw-input {
					&[type='password'] {
						color: $red;
					}
				}
			}

			// Multiselect seek to have custom position on delaer-webapp... :)
			.mw-multiselect .multi-select-list.multi-select-list-dark {
				margin-bottom: ($marginGeneric * 3) * -1;
				top: ($marginGeneric * 3 + 2) * -1;

				@media (max-width: $breakpointTabletMobile) {
					margin-bottom: ($marginGeneric * 4) * -1;
					top: ($marginGeneric * 5 + 2) * -1;
				}
			}

			.mw-radio input + label {
				margin-bottom: $marginGeneric * 3;
			}
		}
	}

	.mw-feedback-tooltip-content {
		font-size: $body-font-small;
		line-height: 20px;
	}
	// we need to adopt dart button for out theme
	button.mw-button.mw-theme-dark.mw-button-secondary {
		background-color: $dark_grey_1;
		border-color: $dark_grey_3;

		&.mw-button-active {
			@include mw-active;
		}

		&:hover {
			@include mw-hover;
		}

		&:focus {
			@include mw-focus;
		}
	}

	select:not([data-thc-style-override]) {
		border-radius: math.div($marginGeneric, 2);

		&:hover {
			@include mw-hover;
		}

		&:focus {
			@include mw-focus;
		}
	}

	.mw-range-slider {
		background-color: var(--thc-color-primary-yellow);
	}
}

.animated {
	animation-duration: 0.5s;
	animation-fill-mode: both;
}

.signInZoomIn {
	animation-name: signInZoomIn;
}

.signInZoomOut {
	animation-name: signInZoomOut;
}

$headerHeightDesktop: 96px;
$footerHeightDesktop: 468px;
$headerHeightDesktopSmall: 80px;
$footerHeightDesktopSmall: 435px;
$headerHeightTablet: 64px;
$footerHeighTabletSmall: 490px;

/* stylelint-disable-next-line selector-id-pattern */
body #__next {
	transition: transform 0.2s linear;
}

body #main {
	min-height: calc(100vh - $footerHeightDesktop - $headerHeightDesktop);

	@media (max-width: $breakpointContent) {
		min-height: calc(100vh - $footerHeightDesktopSmall - $headerHeightDesktopSmall);
	}

	@media (max-width: $breakpointTablet) {
		min-height: calc(100vh - $footerHeightDesktopSmall - $headerHeightTablet);
	}

	@media (max-width: $mqBreakpointTablet) {
		min-height: calc(100vh - $footerHeighTabletSmall - $headerHeightTablet);
	}

	#signup-content {
		overflow: initial;
	}
}

// Hotjar Ammendments
._hj_feedback_container ._hj-G09L\+__MinimizedWidgetMiddle__container {
	top: 85% !important;

	&._hj-v4Fsu__MinimizedWidgetMiddle__right {
		bottom: 0 !important;
	}
}

._hj_feedback_container
	._hj-lOIQj__ExpandedWidget__outerContainer._hj-rPPi2__ExpandedWidget__button._hj-8t8aG__ExpandedWidget__middle {
	top: 72% !important;
}

:where(a[href^='tel:']) {
	white-space: nowrap;
}
