@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/_color' as color;

$buttonSize: 40px;
$buttonZIndex: 999;
$iconSize: 24px;

.container {
	bottom: core.$space3;
	position: fixed;
	right: core.$space3;
	z-index: $buttonZIndex;

	.button {
		align-items: center;
		background-color: color.$primary-yellow;
		border: none;
		border-radius: 50%;
		color: color.$primary-black;
		cursor: pointer;
		display: flex;
		height: $buttonSize;
		justify-content: center;
		width: $buttonSize;

		&:hover {
			background-color: lighten($color: color.$primary-yellow, $amount: 10);
		}
	}

	.icon {
		align-items: center;
		display: flex;
		height: $iconSize;
		justify-content: center;
		overflow: hidden;
		position: relative;
		width: $iconSize;

		--thc-icon-accent-fill: var(--button-background-color);
		--thc-icon-fill: #{color.$primary-black};
		--thc-icon-size: #{$iconSize};
	}

	[data-thc-icon='chevron'],
	[data-thc-icon='speech'] {
		position: absolute;
		transition:
			transform 0.3s ease,
			opacity 0.3s ease;
	}

	.icon.closed [data-thc-icon='speech'] {
		opacity: 1;
		transform: translateY(0);
	}

	.icon.open [data-thc-icon='speech'] {
		opacity: 0;
		transform: translateY(-100%);
	}

	.icon.closed [data-thc-icon='chevron'] {
		opacity: 0;
		transform: translateY(100%) rotate(-90deg);
	}

	.icon.open [data-thc-icon='chevron'] {
		opacity: 1;
		transform: translateY(0) rotate(-90deg);
	}
}
